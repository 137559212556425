
.container {
    text-align: center;
  }
  
  /* Slider */
  .slick-slide {
    margin: 0px 20px;
  }
  
  .slick-slide img {
    width: 100%;
    max-height: 200px; /* Adjust the height as needed */
    object-fit: contain; /* Ensures the image scales proportionally */
  
  }
  
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .slick-track {
    display: flex;
    align-items: center;
  }
  
  /* Background and spacing */
  .clientcontainer {
   
    padding-bottom: 10px;
  }
  
  /* Arrow button customization */
  .slick-prev:before, .slick-next:before {
    font-size: 30px !important;
    color: black;
  }

  .clientcontainer {
    margin: 20px 0;
  }
  
  .clientcontainer {
    margin: 20px 0;
  }
  
  .video-overlay {
    position: relative;
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
  
  .video-overlay iframe {
    pointer-events: none; /* Prevents interaction with the iframe */
  }
  
  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .video-modal-content {
    position: relative;
    background: #000;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    width: 800px;
    overflow: hidden;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    color: #fff;
    font-size: 24px;
    border: none;
    cursor: pointer;
  }
  
  .slide iframe {
    border-radius: 10px;
  }
  