.homebackground2 {
    background-image: url('https://images.pexels.com/photos/1583244/pexels-photo-1583244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover; /* Ensures the image covers the entire element */
    background-position-y: center; 
    background-color: orange;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh; 
    position: relative; /* Changed to relative for better scrolling behavior */
    opacity: 0.6;
    background-attachment: fixed;
    overflow-x: hidden;
}

.backopacity2 {
    background-color: #FFBF78;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: 0.3;
    z-index: 1;
    
}

@media only screen and (max-width: 600px)  {
    .homebackground2 {
        width: 110% !important;
        height: 300vh; /* Increase height to 200% of viewport height */
     
    }
}

.homelarge{
    background-image: url('https://images.pexels.com/photos/1583244/pexels-photo-1583244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover; /* Ensures the image covers the entire element */
    background-position-y: center; 
    background-color: orange;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh; 
    position: relative; /* Changed to relative for better scrolling behavior */
    opacity: 0.6;
    background-attachment: fixed;
    overflow-x: hidden;
}

@media only screen and (max-width: 600px)  {
    .homelarge{
        width: 110% !important;
        height: 400vh; /* Increase height to 200% of viewport height */
     
    }
}