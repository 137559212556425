@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
.contact-us {
    padding: 20px;
    font-family: Arial, sans-serif;
    position: absolute;
    top:15%;
    left:5%;

  }
  
  .contactsubtitle{
    font-family: "Josefin Sans", sans-serif;
    color:orange;
    font-size: 1.5rem;
  }

  .contact-details {
    margin-bottom: 20px;
  }
  
  .contact-details p {
    margin: 5px 0;
  }
  .contacttitle{
    margin-bottom: 20px;
    color:orange;
    border-bottom:2px solid orange;
    font-family: "Josefin Sans", sans-serif;
    border-width: 50%;
  }

  .mapheader{
    font-family: "Raleway", sans-serif;
  }
