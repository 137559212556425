

.videoplayer{
    width: 100%;
    height:40vh;
    border: 4px solid black;
  
}

@media screen and (max-width: 600px) {
    .videoplayer{
        width: 100%;
        height: 30vh;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
}

/* From Uiverse.io by VD232004 */ 
.homecard {
    width: 100%;
    height:40vh;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    overflow: visible;
  }
  
  .homecard-details {
    color: orange;
    height: 100%;
    gap: 0.5em;

    place-content: center;
    text-align: center;
  }
  
  .home-card-button {
    text-decoration: none;
    text-align: center;
    -webkit-transform: translate(-50%, 125%);
    -ms-transform: translate(-50%, 125%);
    transform: translate(-50%, 125%);
    width: 70%;
    border-radius: 1rem;
    border: none;
    background-color: #ff7f38;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .card-text-body {
    color: rgb(134, 134, 134);
  }
  
  /*Text*/
 .cardimg{
    height: 10vh;
    justify-self: center;
 }
  
  /*Hover*/
  .homecard:hover {
    border-color: #ff7f38;
    -webkit-box-shadow: 10px 5px 18px 0 rgba(255, 255, 255, 0.877);
    box-shadow: 10px 5px 18px 0 rgba(255, 255, 255, 0.877);
  }
  
  .homecard:hover .home-card-button{
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    opacity: 1;
  }

  @media  screen and (min-width:1200px) {
    .homecard{
        margin-top: 20px;
    }
    .pdf-embed{
      margin-top: 20px;
    }
    
  }

  /*calendar position */

  .calendarposition{
    margin-top: 30px;
  }


/* Make the entire PDF container clickable */

/* Container for PDF preview */
.pdf-container {
  position: relative;
  width: 100%;
  height: 40vh;
}

/* PDF iframe styling */
.pdf-embed {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #f5f5f5;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  cursor: pointer; /* Keeps the cursor as a pointer */
  pointer-events: none;
}

/* Full PDF button (hidden initially) */
.full-pdf-button {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff7f38;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: 0; /* Make sure the button is hidden initially */
}

/* Show the button when hovering over the iframe */
.pdf-container:hover .full-pdf-button {
  display: block;
  opacity: 1;
  transform: translate(-50%, 50%); /* This will smoothly transition the button to its final position */
}

/* Make the entire PDF container clickable */
.pdf-link {
  display: block;
  width: 100%;
  height: 100%;
}

.prev_image{
  padding: 0px !important;
}
