
/* Default states for paragraphs and headings before animation */
.para1, .para2, .para3, .para4, .para5,.para6, .headcontent1 {
    opacity: 0;
    transform: translateY(20px); /* Start with a slight translation down */
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  /* Animate class that gets added when element is in view */
  .animate {
    opacity: 1;
    transform: translateY(0); /* Bring it to its original position */
  }
  
 .line2 {
    width: 0%;
    height: 5px; /* Height of the border */
    background: linear-gradient(262deg, rgb(255, 127, 56) 0%, rgb(255, 196, 108) 100%);
    transition: width 1.5s ease;
  }
  
  /* Animate line width when it's visible */
 .line2.animate {
    width: 100%;
  }
  