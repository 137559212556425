.homebg {
    background-image: url('https://images.pexels.com/photos/1583244/pexels-photo-1583244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover; /* Ensures the image covers the entire element */
    background-position-y: center; 
    background-color: orange;
    background-repeat: no-repeat;
    width: 100%;
    height: 165vh; 
    position: relative; /* Changed to relative for better scrolling behavior */
    opacity: 0.6;
    background-attachment: fixed;
    overflow-x: hidden;
}

.backopacity1 {
    background-color: #FFBF78;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: 0.3;
    z-index: 1;
    
}

@media only screen and (max-width: 600px)  {
    .homebg {
        width: 110% !important;
        min-height: 270vh; /* Increase height to 200% of viewport height */
        max-height: 535vh;
    }
}